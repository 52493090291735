import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styled from "styled-components";
import { Pane } from "../Common/SplitPane";
import {Avatar} from "./Avatar";
import history from '../../core/history';
import ContactsLinkedin from '../Icons/ContactsLinkedin';
import ContactsFacebook from '../Icons/ContactsFacebook';
import ContactsMessenger from '../Icons/ContactsMessenger';
import ContactsTwitter from '../Icons/ContactsTwitter';
import ContactsInstagram from '../Icons/ContactsInstagram';
import ContactsYoutube from '../Icons/ContactsYoutube';
import ContactsIce from '../Icons/ContactsIce';
import ContactsTalk from '../Icons/ContactsTalk';
import ContactsYahoo from '../Icons/ContactsYahoo';
import ContactsTelegram from '../Icons/ContactsTelegram';
import CustomTooltip from '../Common/CustomTooltip';
import CopyToClipboard from '../Common/CopyToClipboard';
import Tags from '../Common/Tags/Tags';
import { WithUserUploads } from '../../core/HOC';
import Collapse from '../Common/Collapse';
import ExtendedForm from '../Common/ExtendedForm';
import Attachments from '../Cargo/Preview/Attachments';
import UploadedFileExtensionErr from '../Common/UploadedFileExtensionErr';
import { HiddenButtons } from '../Common/HiddenButtons';
import { AddAttachment } from '../Monitor/Edit/Attachments';
import { scrollToAnchor, UUIDToObjectId } from '../../core/utils';
import CommonComments from '../Common/Comments/CommonComments';
import DropDownIco from 'material-ui/svg-icons/navigation/arrow-drop-down';
import { date } from '../../core/format';
import DefaultPopover from '../Common/DefaultPopover';
import CircleEditBtn from '../Common/CircleEditBtn';
import cx from 'classnames';
import {phoneTypeMap} from "./ContactPhonesEdit";
import Link from '../Link/Link';
import CollapseField from '../Common/CollapseField';
import Activity from './Activity';
import DialogHalfScreen from '../Common/DialogHalfScreen';
import EmailDetails from '../Emails/EmailDetails';
import ChatAndMailIcon from '../Icons/ChatAndMailIcon';
import WithTooltip from '../Common/WithTooltip';

const Body = styled.div`
  padding: 20px 20px 48px 20px;
  color: var(--text-dark333);
  font-size: 14px;
  .contact-name-wrapper {
    display: flex;
    .contact-name-block {
      position: relative;
      flex: 1;
      margin-left: 24px;
      .contact-name, .contact-surname {
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
      }
      .contact-name {
        padding-right: 24px;
      }
      .contact-position {
        padding: 8px 0;
        font-size: 12px;
        color: #999;
      }
      .contact-position-popover {
        padding: 12px;
      }
      .contact-edit-icon {
        height: fit-content;
        position: absolute;
        right: 0;
        top: 0;
      }
      .social-media-block {
        display: flex;
        .social-media {
          margin-right: 8px;
          .social-media-icon {
            transition: opacity linear 0.2s;
            > :hover {
              opacity: 0.75;
            }
          }
        }
      }
    }
  }
  .contact-info-wrapper {
    .contact-info-block {
      display: flex;
      justify-content: space-between;
      .contact-info-1col, .contact-info-2col {
        width: 49%;
        .contact-info-field {
          padding-top: 12px;
          .contact-phones-collapse, .contact-emails-collapse {
            max-height: unset;
            > div:first-child {
              flex-direction: row-reverse;
              justify-content: start;
              position: relative;
              svg {
                position: absolute;
                bottom: 0;
                right: 45%;
              }
              h3 {
                font-size: 14px;
                text-transform: none;
                font-weight: 400;
                line-height: inherit;
                .contact-info-field-label {
                  padding-bottom: 4px;
                  font-size: 12px;
                  color: #999;
                }
              }
            }
          }
          .contact-emails-collapse {
            > div:first-child {
              svg {
                right: 25%;
              }
            }
          }
        }
        .contact-info-field-label {
          padding-bottom: 4px;
          font-size: 12px;
          color: #999;
        }
      }
    }
    .contact-details-field {
      padding: 12px 0px;
      h3 {
        margin: 0;
        font-size: 12px;
      }
      .contact-details-label {
        padding-bottom: 4px;
        font-size: 12px;
        color: #999;
      }
      .contact-first {
        font-size: 12px;
      }
    }
    .add-attachment-btn {
      display: flex;
      background-color: #000 !important;
    }
  }
`;

const AddAttachmentWrapper = styled.div`
  display: flex;
  label {
    svg {
      path {
        fill: #4380C7 !important;
      }
    }
    span {
      color: #4380C7 !important;
    }
  }
`;

const TAGS_AUTOCOMPLETE_PROPS = {
  style: { zIndex: 10, position: "relative" },
  apiMethodName: 'getAllContactTags',
};

export const FavoriteContactButton = styled(({className, toggled, onToggle , ...props}) => {
  return <div onClick={onToggle} className={className}>
    { toggled ? <span className="material-icons">star</span> : <span className="material-icons-outlined">star_outline</span> }
  </div>
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--stroke-gray-2, #E6E6E6);
  background: white;
  cursor: pointer;
  >span {
    font-size: 18px;
    color: var(--text-links-light);
  }
`

class ContactView extends Component {
  static contextTypes = {
    showMessage: PropTypes.func,
  };

  static defaultProps = {
    onClose: () => history.push(`/$1/$2/-/-/-/---`),
    companyLinkPropsGenerator: (c) => {
      if (window && window.location) {
        const currentLocation = window.location.pathname.split("/");
        return { to: `/${currentLocation[1]}/${currentLocation[2]}/company:${c.id}/-/-/---` };
      }
      return { to: `/$1/$2/company:${c.id}/-/-/---` };
    },
  }

  constructor(props) {
    super(props);
    this.state = {
      attachmentsChanged: false,
      attachmentsKey: 1,
      attachments: props.contact.attachments || [],
      initialAttachments: props.contact.attachments || [],
      tags: props.contact.tags || [],
      };
  }
  collapses = {};

  handleClose = () => {
    this.props.onClose();
    if (this.props.closeEmailsList) {
      this.props.closeEmailsList();
    }
  }

  openPositionPopover = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({
      positionOpen: true,
      positionAnchor: ev.currentTarget,
    });
  }
  closePositionPopover = () => {
    this.setState({
      positionOpen: false,
    });
  }

  removeAttachment = (e, id) => {
    const newAttachments = this.state.attachments.filter(
      attachment => attachment?._id !== id
    );
    this.setState(
      state => ({
        attachments: newAttachments,
        attachmentsKey: state.attachmentsKey + 1 || 1
      }),
      this.checkForAttachmentsChanges.bind(this, newAttachments)
    );
  };

  cancelAttachments = () => {
    this.setState(state => ({
      attachmentsKey: state?.attachmentsKey + 1 || 1,
      attachments: state.initialAttachments,
      attachmentsChanged: false,
    }));
  };

  handleSaveAttachments = () => {
    const attachmentIds = this.state.attachments.map(a => a._id);
    this.props.updateContact({ id: this.props.contact.id, attachmentIds: attachmentIds }).then(res => {
      if (res.data) {
        this.setState({
          attachments: res.data.attachments,
          initialAttachments: res.data.attachments,
          attachmentsChanged: false,
        });
        this.props.contact.attachments = res.data.attachments;
      }
    });
  }

  setAttachmentsChange = () => this.setState({ attachmentsChanged: true });
  invalidSubmit = (model) => {
    const invalid =
      document.querySelector(`input[data-valid=false]`) ||
      document.querySelector(`.error`) ||
      document.querySelector(`.with_error`);

    this.openCollapsesWithErrors();
    if (invalid) {
      scrollToAnchor(invalid);
    }
  };

  openCollapsesWithErrors = () => {
    for (let ref in this.collapses) {
      if (this.collapses[ref] && this.collapses[ref].findInvalidInputs) {
        this.collapses[ref].findInvalidInputs();
      }
    }
  };

  onFilesUploaded = (validatedFiles, uploadedFiles) => {
    const newAttachments = [...this.state.attachments, ...(uploadedFiles.map(f => ({ ...f, canEdit: true })))];
    this.setState(
      state => ({ attachments: newAttachments }),
      this.checkForAttachmentsChanges.bind(this, newAttachments)
    );
    this.collapses.attachmentsConsCollapse &&
      this.collapses.attachmentsConsCollapse.show();
  };

  checkForAttachmentsChanges = newAttachments => {
    const noChanges =
      newAttachments.every(
        (attachment, i) =>
          this.state.initialAttachments.findIndex(
            a => a._id === attachment._id
          ) >= 0
      ) && newAttachments.length === this.state.initialAttachments.length;
    this.setState({
      attachmentsChanged: !noChanges,
    });
  };
  handleSaveTags = async (tag) => {
    const newTags = [...this.state.tags, tag].filter(t => t.canEdit !== false);
    this.props.updateContact({ id: this.props.contact.id, tags: newTags }).then(res => {
      if (res.data) {
        this.context.showMessage({
          message: "Tag was successfully added.",
        });
        this.setState({ tags: res.data.tags });
      }
    });
  }
  handleDeleteTag = async (tag) => {
    const newTags = this.state.tags.filter(t => t.id !== tag.id).filter(t => t.canEdit !== false);
    this.props.updateContact({ id: this.props.contact.id, tags: newTags }).then(res => {
      if (res.data) {
        this.context.showMessage({
          message: "Tag was successfully deleted.",
        });
        this.setState({ tags: res.data.tags });
      }
    });
  }

  openMailThread = () => {
    this.setState({ mailThreadOpened: true });
  }
  closeMailThread = () => {
    this.setState({ mailThreadOpened: false });
  }

  openMalilList = () => {
    this.props.openEmailsList(this.props.contact.emails); 
  }

  render() {
    const contact = this.props.contact;
    const { attachments, initialAttachments, attachmentsChanged, attachmentsKey, positionOpen, positionAnchor, tags } = this.state;
    return (
      <Pane
        bodyProps={{ style: { height: '100%' } }}
        headerProps={{
          useBorder: true,
          children: <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <FavoriteContactButton toggled={contact.faved} onToggle={this.props.toggleFavorites} />
            {!this.props.isGlobalPopover && <WithTooltip
              horizontalPosition="left"
              whiteArrow={false} tip={<div className={cx("tooltip-default", "dark")} >View&nbsp;email&nbsp;history</div>}
            >
              <span onClick={this.openMalilList} style={{ cursor: "pointer", marginRight: '4px' }}>
                <ChatAndMailIcon />
              </span>
            </WithTooltip>}
          </div>,
          onClose: this.handleClose,
          style: { flexWrap: "nowrap" },
        }}
        scrollable
      >
        <Body>
          <div className={'contact-name-wrapper'}>
            <Avatar contact={contact} size={'large'} />
            <div className={'contact-name-block'}>
              <div className={'contact-name'}>{contact.name}</div>
              <div className={'contact-surname'}>{contact.surname}</div>
              <div onClick={this.openPositionPopover} className={'contact-position'}>
                {contact.position}
                {contact.positionNotes && <DropDownIco style={positionOpen ? { transform: "rotate(180deg)" } : null} />}
              </div>
              <DefaultPopover
                open={positionOpen}
                anchor={positionAnchor}
                handleClose={this.closePositionPopover}
              >
                <div style={{ padding: "12px" }}>
                {contact.positionNotes}
                </div>
              </DefaultPopover>
              <SocialMedia contact={contact} />
              <div className={"contact-edit-icon"}>
                <CircleEditBtn iconStyle={{ width: "14px", height: "14px" }} style={{ width: "20px", height: "20px" }} onClick={this.props.onEdit} />
              </div>
            </div>
          </div>
          <div className={"contact-info-wrapper"}>
            <div className={'contact-info-block'}>
              <div className={"contact-info-1col"}>

                <div className={'contact-info-field'}>
                  <CollapseField
                    title={
                        <div className={'contact-info-field nopadding'}>
                          <div className={'contact-info-field-label'}>
                            Email
                          </div>
                            <div>
                              {contact.emails.length ? <a href={`mailto:${contact.emails[0]}`} >{contact.emails[0]}</a> : "---"}
                            </div>
                        </div>
                      }
                  >
                  { contact.emails.length > 1 && contact.emails.map((email, i) => {
                      if (i > 0) {
                        return (
                          <div key={email}>
                            <a href={`mailto:${email}`} >{email}</a>
                          </div>);
                      }
                      return null;
                    })}
                  </CollapseField>

                  </div>

                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Company
                  </div>
                  <div>
                  {contact.companies.length ? (contact.companies.map((c, i) => (<span>
                      <Link key={c.id} {...this.props.companyLinkPropsGenerator(c)} >
                        {c.name}
                      </Link>{i < contact.companies.length - 1 ? ", " : null}
                    </span>))) : '---'}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Alternative Address
                  </div>
                  <div>
                    {contact.altAddress || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Birth Date
                  </div>
                  <div>
                    { contact.birthDate ? date(contact.birthDate) : '---' }
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Skype
                  </div>
                  <div>
                    {contact.skype || "---"}
                  </div>
                </div>
              </div>
              <div className={"contact-info-2col"}>
                <div className={'contact-info-field'}>
                <CollapseField
                    title={
                      <div className={'contact-info-field nopadding'}>
                        <div className={'contact-info-field-label'}>
                          { phoneTypeMap[contact.phones[0]?.type || 'landline']}
                        </div>
                        {contact.phones.length ? contact.phones[0].number : "---"}
                      </div>
                      }
                >
                  { contact.phones.length > 1 && contact.phones.map((phone, i) => {
                        if (i > 0) {
                          return (
                            <div className={'contact-info-field'}>
                              <div className={'contact-info-field-label'}>
                                {phoneTypeMap[phone.type]}
                              </div>
                              {phone.number}
                            </div>);
                        }
                        return null;
                      })}
                  </CollapseField>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Company Address
                  </div>
                  <div>
                    {contact.address || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Marital Status
                  </div>
                  <div>
                    {contact.maritalStatus || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Website
                  </div>
                  <div>
                    {contact.website || "---"}
                  </div>
                </div>
                <div className={'contact-info-field'}>
                  <div className={'contact-info-field-label'}>
                    Important Date
                  </div>
                  <div>
                    {contact.importantDates.length ? contact.importantDates.map(importantDate =>
                      <div key={importantDate.date}>{`${date(importantDate.date)} ${importantDate.note || ''}`}</div>
                    ) : "---"}
                  </div>
                </div>
              </div>
            </div>

            <div className={'contact-details-field'}>
              <h3>ADDITIONAL DETAILS</h3>
              <div className={'contact-first'}>
                {contact.details || "---"}
              </div>
            </div>
          </div>
          <Activity contact={contact} openMailThread={this.openMailThread} isGlobalPopover={this.props.isGlobalPopover}/>
          <Tags handleSave={this.handleSaveTags} handleDelete={this.handleDeleteTag} tags={tags} opened isContactTags entity="cargoRequest" autocompleteProps={TAGS_AUTOCOMPLETE_PROPS} />
          <WithUserUploads key={attachmentsKey} shouldUpload onSuccess={this.onFilesUploaded} maxFiles={Number.MAX_VALUE}>
                {({ erroredFiles, validateUserUploads, uploadedFiles, accept, uploading }) => {
                  return (<Collapse
                    defaultOpen
                    innerRef={el => (this.collapses.attachmentsConsCollapse = el)}
                    title={initialAttachments.length ? `ATTACHMENTS (${initialAttachments.length})` : "ATTACHMENTS"}
                    additionals={
                      <AddAttachmentWrapper>

                        <AddAttachment
                          key={attachmentsKey}
                          changesCallback={this.setAttachmentsChange}
                          success={validateUserUploads}
                        />
                      </AddAttachmentWrapper>
                    }
                  >
                    <ExtendedForm
                      ref="attachmentsForm"
                      noValidate
                      onValidSubmit={this.handleSaveAttachments}
                      onInvalidSubmit={this.invalidSubmit}
                    >
                      <Attachments
                        changesCallback={this.setAttachmentsChange}
                        handleRemove={this.removeAttachment}
                        attachments={attachments}
                      />
                      <div>{erroredFiles.length ? <UploadedFileExtensionErr className={'upload_error'} accept={accept} erroredFiles={erroredFiles} /> : null}</div>
                      <HiddenButtons
                        shouldShow={attachmentsChanged}
                        handleCancel={this.cancelAttachments}
                      />
                    </ExtendedForm>
                  </Collapse>);
                }}
          </WithUserUploads>
          <CommonComments isContactComment publicToggleEnabled={false} entityType={'contact'} entity={this.props.contact} />
        </Body>
        {this.state.mailThreadOpened && contact.lastEmailId ?
          <DialogHalfScreen position="left" open>
            <EmailDetails
              emailId={contact.lastEmailId}
              handleClose={this.closeMailThread}
              showRecognizedItems={false}
              shouldConfirmClose
            />
          </DialogHalfScreen>
        : null}
      </Pane>
    );
  }
}

const Tip = styled.div`
  display: flex;
  align-items: center;
  button {
    transition: fill linear 0.2s;
    svg {
      fill: #fff !important;
    }
    > :hover {
      svg {
        fill: var(--text-links-light, #82C3FF) !important;
      }
    }
  }
  a {
    text-decoration: underline;
    color: var(--text-links-light, #82C3FF);
  }
`;
export class SocialMedia extends Component {

  tooltip = (text) => {
    if (text.startsWith("http")) {
      return (
        <Tip>
          <a href={text} rel="noopener noreferrer" target="_blank" >{text}</a>
          <CopyToClipboard noPadding text={text} />
        </Tip>
      );
    }
    return (
      <Tip>
        {text}
        <CopyToClipboard noPadding text={text} />
      </Tip>
    );
  }

  render() {
    const { linkedin, twitter, facebook, messenger, instagram, youtube, iceName, kakaoName, yahoo, telegramName } = this.props.contact;
    return (
      <div className="social-media-block">
        {linkedin && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(linkedin)}>
            <ContactsLinkedin className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {facebook && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(facebook)}>
            <ContactsFacebook className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {messenger && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(messenger)}>
            <ContactsMessenger className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {twitter && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(twitter)}>
            <ContactsTwitter className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {instagram && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(instagram)}>
            <ContactsInstagram className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {youtube && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(youtube)}>
            <ContactsYoutube className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {iceName && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(iceName)}>
            <ContactsIce className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {kakaoName && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(kakaoName)}>
            <ContactsTalk className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {yahoo && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(yahoo)}>
            <ContactsYahoo className="social-media-icon" />
          </CustomTooltip>
        </div>}
        {telegramName && <div className="social-media">
          <CustomTooltip dark tooltip={this.tooltip(telegramName)}>
            <ContactsTelegram className="social-media-icon" />
          </CustomTooltip>
        </div>}
      </div>
    );
  }
}
export default ContactView;
