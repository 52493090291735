import Cargo from '../core/api/Cargo';
import Vessel from '../core/api/Vessel';
import Orders from '../core/api/Orders';
import * as constants from '../constants/monitor';
import { NAVIGATE } from '../constants/index';
import { isShipnextOrVaramar, prepareRequest, toUTC } from '../core/utils';
import history from '../core/history';
import { defaultCargoSortField, defaultSortField } from './../components/Monitor/shared';
import PubSub from 'pubsub-js';
import CargoApi from "../core/api/Cargo";
export function eraseVesselList() {
  return {
    type: constants.ERASE_VESSEL_LIST
  }
}

export function toggleHiddenVessels(isHidden) {
  return {
    type: constants.TOGGLE_HIDDEN_VESSELS,
    payload: {
      isHidden
    }
  }
}

export function hideUnhideVessels(vessels, shouldHide) {
  return (dispatch, getState) => {
    const plural = vessels.length > 1;
    const successMessage = `Vessel${plural ? "s" : ""} ${plural ? "have" : "has"} been successfully ${shouldHide ? "hidden" : "removed from hidden"}`
    const data = {
        vesselIds: vessels,
        hide: shouldHide
    }
    return dispatch({
      type: constants.SHOW_HIDE_VESSELS,
      payload: data,
      promise: Vessel.hideUnhideVessel(data),
      successMessage: successMessage
    })
  }
}

export function createTCOrder(payload) {
  return async function(dispatch, getState) {
    try {
      const res = await dispatch({
        type: constants.CREATE_TC_ORDER,
        payload,
        promise: Orders.createTCOrder(cleanEmptyRedelivery(payload)),
        successMessage: 'T/C Order has been created',
      });
      if (res.status > 399) throw res;
    } catch (error) {
      console.log(error);
      throw error;
    }
    const { filters } = getState().monitor.tcList;
    return dispatch(getTCOrders({ filters, actionType: constants.HANDLE_LOAD_TC_ORDERS }))
  }
}

export function updateAllVesselsInMainDeck(updateObj) {
  return {
    type: constants.MAIN_DECK_UPDATE_ALL_VESSELS,
    payload: updateObj
  }
}

export function changeVesselStatus(payload, statusId, val) {
  return async (dispatch, getState) => {
    try {
      const res = await Vessel.changeVesselStatus(payload, statusId, val);
      if (res?.status === 200 || !res?.errors || res?.errors?.length === 0) {
        dispatch({
          type: "MESSAGE",
          payload: {
            level: 'success',
            title: `Vessel's status successfully updated!`,
            message: payload.vesselGroups && payload.vesselGroups.groups?.length > 0 ? `Current statuses ${payload.vesselGroups.groups?.join(', ')}` : "Vessel doesn't have status",
          }
        })
        return dispatch({
          type: constants.CHANGE_VESSEL_STATUS,
          payload
        })
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'MESSAGE',
        payload: {
          level: 'error',
          title: "Error changing vessel status",
          message: error?.message?.statusText,
        }
      })
    }
  }
}

export function changePositionStatus(payload) {
  return async dispatch => {
    try {
      const res = await Vessel.changePositionStatus(payload);
      return dispatch({
        type: constants.CHANGE_POSITION_STATUS,
        payload
      })
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'MESSAGE',
        payload: {
          level: 'error',
          title: "Error changing position status",
          message: error?.message?.statusText,
        }
      })
    }
  }
}

export function filterTCOrders(payload) {
  return {
    type: constants.TC_ORDERS_FILTER,
    payload,
  };
}

export function updateTcOrdersList(payload) {
  return {
    type: constants.UPDATE_TC_ORDERS_LIST,
    payload,
  }
}
export function updateCargoList(payload) {
  return {
    type: constants.UPDATE_CARGO_LIST,
    payload,
  }
}
export function updateVesselsList(payload) {
  return {
    type: constants.UPDATE_VESSELS_LIST,
    payload,
  }
}

export function getTCOrders({filters, sort, page, next, actionType = constants.GET_TC_ORDERS}) {
  return (dispatch, getState) => {
    const { monitor: { tcList: { filters: savedFilters, sort: savedSort, page: savedPage, next: savedNext } = {} } = {} } = getState();
    filters = filters || savedFilters || {};
    sort = sort || savedSort || {};
    page = page || savedPage || 1;
    next = next || savedNext || null;
    const payload = {
      filters,
      sort,
      page,
      next
    }
    return dispatch({
      type: actionType,
      payload,
      promise: Orders.getTCOrders(payload),
    })
  }
}

export function handleChange(key, ev, val) {
  return {
    type: constants.HANDLE_CHANGE_FIELD,
    payload: {
      key,
      val
    }
  }
}
export function handleReset() {
  return {
    type: constants.HANDLE_RESET_FORM,
  }
}
export function setTCForm(form) {
  return {
    type: constants.SET_TC_FORM,
    payload: {
      form
    }
  }
}

export function getCargoList(params) {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.login.user;
    const lastFilters = state.login.lastFilters;
    if (!user) {
      if ((params.sort.field !== defaultCargoSortField.field || params.sort.value !== -1)
        || params.filters.onlyMy
        || params.filters.cancellingDate
        || params.filters.cargoName
        || params.filters.cargoType
        || params.filters.loadingPort
        || params.filters.readinessDate
        || params.filters.unloadingPort
        || params.filters.volumeMax
        || params.filters.volumeMin
        || params.filters.weightMax
        || params.filters.weightMin
        || params.filters.search
      ) {
        return history.push('/login');
      }
    }

    if (params.filters) {
      if (params.filters.weightMin) {
        params.filters.weightMin *= 1000;
      }
      if (params.filters.weightMax) {
        params.filters.weightMax *= 1000;
      }
      if (params.filters.loadingPort && params.filters.loadingPort.areaParent) { //TODO e.fedorov check relevance
        params.filters.loadingPortRegion = true;
      } else {
        params.filters.loadingPortRegion = false;
      }
      if (params.filters.unloadingPort && params.filters.unloadingPort.areaParent) {
        params.filters.unloadingPortRegion = true;
      } else {
        params.filters.unloadingPortRegion = false;
      }
    }
    if (lastFilters && lastFilters.deckCargoFilter) {
      params.filters = { ...params.filters, ...lastFilters.deckCargoFilter };
      params.sort = { ...params.sort, ...lastFilters.deckCargoFilter.sort };
    }
    return dispatch({
      type: constants.MONITOR_CARGO_LIST,
      promise: Cargo.getCargoList(prepareRequest(params)),
      payload: params,
      takeLatest: true,
    });
  };
}
export function getVesselList(params) {

  return async (dispatch, getState) => {
    const state = getState();
    const user = state.login.user;
    const lastFilters = state.login.lastFilters;

    if (!user) {
      if ((params.sort.field !== defaultSortField.field || params.sort.value !== -1)
        || params.filters.gear
        || params.filters.maxDWT
        || params.filters.minDWT
        || params.filters.onlyMy
        || params.filters.openFrom
        || params.filters.openPort
        || params.filters.openUntil
        || params.filters.type
        || params.filters.search
      ) {
          return history.push('/login');
      }
    }
    params.hidden = state.monitor.isHidden || false;

    if (lastFilters && lastFilters.deckVesselFilter) {
      params.filters = { ...params.filters, ...lastFilters.deckVesselFilter };
      delete params.filters.search;
      if (params.filters.tags && !params.filters.tags.condition.length) {
        delete params.filters.tags;
      }
      params.sort = { ...params.sort, ...lastFilters.deckVesselFilter.sort };
    }
    /*if (params.filters.openPort && params.filters.openPort.areaParent) {
      params.filters.region = true;
    } else {
      params.filters.region = false;
    }*/

    return dispatch({
      type: constants.MONITOR_VESSEL_LIST,
      promise: Vessel.getVesselList(prepareRequest(params)),
      payload: params,
      takeLatest: true,
    });
  };
}

export function dragStartCargo(cargo) {
  return {
    type: constants.MONITOR_DRAGSTART_CARGO,
    payload: cargo,
  };
}

export function dragEndCargo(cargo) {
  return {
    type: constants.MONITOR_DRAGEND_CARGO,
    payload: cargo,
  };
}
export function dragStartVessel(vessel) {
  return {
    type: constants.MONITOR_DRAGSTART_VESSEL,
    payload: vessel,
  };
}

export function dragEndVessel(vessel) {
  return {
    type: constants.MONITOR_DRAGEND_VESSEL,
    payload: vessel,
  };
}
export function dropCargo(vessel, cargo) {
  let route;
  if (vessel.addedByUser) {
    route = `/main-deck/general/${cargo._id}/${vessel._id}/`;
  } else {
    route = `/main-deck/general/${cargo._id}/${vessel._id}/`;
  }
  return {
    type: NAVIGATE,
    payload: { route },
  };
}
export function dropVessel(vessel, cargo) {
  let route;
  if (cargo.addedByUser) {
    route = `/main-deck/general/${cargo._id}/${vessel._id}/`;
  } else {
    route = `/main-deck/general/${cargo._id}/${vessel._id}/`;
  }
  return {
    type: NAVIGATE,
    payload: { route },
  };
}

export function sendFreightRequest(request) {
  return {
    type: constants.MONITOR_SEND_FREIGHT_REQUEST,
    payload: request,
    promise: Vessel.sendFreightRequest(request),
    successMessage: 'Freight request sent',
  };
}
export function sendOffer(offer) {
  return {
    type: constants.MONITOR_SEND_OFFER,
    payload: offer,
    promise: Cargo.sendOffer(offer),
    redirect: { route: '/main-deck/general/' },
    successMessage: 'Offer sent',
  };
}

export function findRelevantShips(cargoRequestId, page) {
  return {
    type: constants.MONITOR_FIND_RELEVANT_SHIPS,
    payload: cargoRequestId,
    promise: Cargo.findRelevantShips(cargoRequestId, page),
  };
}
export function findRelevantCargoes(vesselRequestId, page) {
  return {
    type: constants.MONITOR_FIND_RELEVANT_CARGOES,
    payload: vesselRequestId,
    promise: Vessel.findRelevantCargoes(vesselRequestId, page),
  };
}
export function sendTcOffer(offer) {
  return {
    type: constants.MONITOR_SEND_TCOFFER,
    payload: offer,
    promise: Cargo.sendTcOffer(offer),
    successMessage: 'Offer sent',
  };
}

export function changeCargoStyle(style) {
  return {
    type: constants.MONITOR_TOGGLE_CARGO_STYLE,
    payload: style,
  };
}

export function changeVesselStyle(style) {
  return {
    type: constants.MONITOR_TOGGLE_VESSEL_STYLE,
    payload: style,
  };
}


function cleanEmptyRedelivery(order) {
  order = { ...order };
  order.redeliveryPlaces = order.redeliveryPlaces.filter(rp => !!rp.port);
  return order;
}

export function changeCargoStatus(cargo, statuses) {
  let isPublic = false;
  const companyId = cargo.addedBy?.company?._id || cargo.company?._id;
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.login.user;
    if (user.company._id === companyId || isShipnextOrVaramar(user)) {
      isPublic = true;
    }
    await dispatch({
      type: constants.MONITOR_CHANGE_CARGO_STATUS,
      payload: { _id: cargo._id, userStatus: { statuses } },
      promise: Cargo.setStatus(cargo._id, statuses),
    });
    await dispatch({
      type: constants.MONITOR_CARGO_PATCH,
      payload: { _id: cargo._id, userStatus: { statuses, isPublic } },
    });
    PubSub.publish("MONITOR_CARGO_PATCH", { _id: cargo._id, userStatus: { statuses, isPublic } });
    return { statuses, isPublic };
  };
}

export function setCargoChangesSeen(cargo, lastChange){
  return async (dispatch, getState) => {
    await dispatch({
      type: constants.MONITOR_SET_SEEN_CARGO_CHANGES,
      payload: { _id: cargo._id, lastChange },
      promise: Cargo.setLastSeenDate(cargo, toUTC(lastChange.setAt)),
    });
    lastChange = { ...lastChange, lastSeenDate: lastChange.setAt };
    await dispatch({
      type: constants.MONITOR_CARGO_PATCH,
      payload: { _id: cargo._id, lastChange },
    });
    PubSub.publish("MONITOR_CARGO_PATCH", { _id: cargo._id, lastChange });
  };
}

export function reloadMonitorCargo(cargoRequest) {
  return async (dispatch, getState) => {
    const user = getState().login.user;
    const newCargoData = await CargoApi.getNewMonitorCargo(cargoRequest);
    if (newCargoData) {
      dispatch({
        type: 'MONITOR_CARGO_UPDATE',
        payload: newCargoData,
        currentUser: user,
        options: { upsert: true },
      });
    }
  }
}
